<template>
  <div class="about">
    <div class="about__content">
      <div class="box about__column-left">
        <span class="two moving_effect" data-direction="x" data-reverse="yes">
        </span>
        <!--  <span class="three moving_effect" data-direction="x"
        data-reverse="yes"></span> -->
        <span
          class="four moving_effect"
          data-direction="x"
          data-reverse="no"
        ></span>
      </div>

      <div class="box about__head text__content">
        <div class="title">
          <span class="small">Hola!, </span>
          <h3 class="">
            <span class="square__position-about">S</span>oy
            <span class="text-red-fluor">Sebastian</span>,
          </h3>
          <!-- <span class="subtitle">Alias Verastian y creo ser</span> -->
          <h3>Web <span class="text-red-fluor">Developer</span></h3>
          <span class="subtitle">Establecido en Santiago,Chile</span>
        </div>
      </div>
      <div class="box about__text">
        <div class="text">
          <p>
            Soy un técnico superior en Electrónica Industrial con título de
            Inacap.
          </p>
          <p>
            Comencé a involucrarme en el desarrollo de aplicaciones web en medio
            del año 2020, a través de un bootcamp de Talento Digital de Sence
            donde adquirí conocimientos en desarrollo Frontend con herramientas
            como Vuejs, Bootstrap y tecnologías para persistir información no
            relacional como Firebase y MongoDB.
          </p>
          <p>
            Luego realicé un curso en el mismo organismo enfocado en desarrollo
            Backend con Java, aprendiendo programación orientada a objetos,
            patrones de diseño y seguridad. Utilicé Spring Framework y
            SpringBoot como herramienta fundamental, y realicé consultas a bases
            de datos relacionales como MySql y Oracle en proyectos CRUD y
            APIRest.
          </p>
          <p>
            Actualmente participo en un proyecto de una plataforma de usuarios y
            sus perfiles de trabajo para la empresa DLAB de Desafio Latam, en el
            cual he participado en la parte del backend con Nodejs y Expressjs y
            la base de datos PostgreSQL.
          </p>
          <p>
            Continúo perfeccionando mis habilidades a través de proyectos
            educativos y personales con el objetivo de acumular experiencia en
            el área del desarrollo
          </p>
        </div>
        <div>
          <a
            class="btn-2 btn"
            :href="srcDownload"
            download="sebastian-vera-cv.jpg"
          >
            <span class="icon" v-html="download"> </span>
          </a>
        </div>
      </div>
      <div class="box about__column-right">
        <div class="images">
          <img
            src="../assets/img/svg/illustrations/1.2.svg"
            loading="lazy"
            decoding="async"
            lazyalt="imagen"
            alt=""
          />
          <span class="two moving_effect" data-direction="x" data-reverse="no">
          </span>
          <!--  <span class="three moving_effect" data-direction="x"
        data-reverse="yes"></span> -->
          <span
            class="four moving_effect"
            data-direction="x"
            data-reverse="yes"
          ></span>
        </div>
      </div>
    </div>

    <Skills />
    <!-- "Y-m-d H:i:s" -->
    <Resume />
    <CountUp date="2020-07-06 18:00:00" />
  </div>
</template>
<script>
import { download } from "../assets/img/svg/icons/iconsSvg";
import Skills from "../components/skills/Skills.vue";
import CountUp from "../components/skills/CountUp.vue";
import Resume from "../components/skills/Resume.vue";
import { movingEffect } from "../utils/effects";
export default {
  name: "About",
  components: {
    Skills,
    CountUp,
    Resume,
  },
  data() {
    return {
      srcDownload: `${process.env.BASE_URL}cv/sebastian-vera-cv.jpg`,
      download: `Download CV ${download}`,
    };
  },
  mounted() {
    movingEffect();
  },
};
</script>
